import { questionnaireIdKey, questionnaireTypeKey, userIdKey } from './store/questionnaire/state';

const query = new URLSearchParams(window.location.hash.substring(1));
const questionnaireId = query.get('questionnaireId') || '';
const userId = query.get('userId') || '';
/**
 * The questionnaireType will be the first path parameter
 *   after the site root (e.g. /onboarding/preapproval)
 */
const questionnaireType = window.location.pathname
  .replace(process.env.SITE_ROOT || '', '')
  .substring(1)
  .split('/')[0];

if (questionnaireId && userId) {
  sessionStorage.setItem(questionnaireTypeKey, questionnaireType);
  sessionStorage.setItem(questionnaireIdKey, questionnaireId);
  sessionStorage.setItem(userIdKey, userId);
}
